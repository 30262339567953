import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import classNames from 'classnames';
import PostHeader from '../post-header';
import PostTitle from '../post-title';
import ContentExcerpt from '../content-excerpt';
import { getCategory } from '../../../common/selectors/categories-selectors';
import contentStateToString from '../../services/content-state-to-string';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './footer-posts-item-desktop.scss';
import { isPostSeen } from '../../services/post-utils';
import { PostSingleRowFooter } from '../post-footer/post-single-row-footer';
import { HorizontalSeparator } from '../separator';

class FooterPostsItem extends Component {
  constructor(props) {
    super(props);
    this.state = { titleLineCount: 1 };
  }

  setLineCount = (titleLineCount) => {
    if (titleLineCount) {
      this.setState({ titleLineCount });
    }
  };

  render() {
    const { category, post, query, borderWidth, titleFontClassName, contentFontClassName } =
      this.props;
    const className = classNames(
      styles.container,
      contentFontClassName,
      'forum-text-color',
      'forum-card-background-color',
      'forum-card-border-color',
      'post-list-item',
    );
    const postLink = `/${category?.slug}/${post?.slug}`;
    const content = contentStateToString(post.content);
    const isEmptyContent = content.trim() === '';

    return (
      <div className={className} style={{ borderWidth }} role="article" data-hook="post-list-item">
        <PostHeader
          type={PostHeader.FOOTER_POST}
          post={post}
          showPinIcon={false}
          showLockIcon={false}
          showBestAnswer={false}
          showMoreButton={false}
          showLastActivityDate={true}
          showDateBelowAvatar={true}
          showAvatarBadges={true}
          truncateBadgeTitle={true}
          showUserInfoInline={false}
          showNewPostIndicator={true}
        />
        <div className={classNames(styles.titleAndContent, styles.contentContainer)}>
          <PostTitle
            className={classNames(titleFontClassName, 'forum-text-color', styles.title)}
            isPostSeen={isPostSeen(post)}
            type={PostTitle.FOOTER_POST}
            title={post.title}
            query={query}
            to={postLink}
            post={post}
            category={category}
            clamp={2}
            setLineCount={this.setLineCount}
            component="div"
          />
          {!!category && (
            <div className={styles.subtitle}>
              <span className={styles.subtitleText}>{category.label}</span>
            </div>
          )}
          {!isEmptyContent && (
            <div className={classNames(styles.content)}>
              <ContentExcerpt
                type={ContentExcerpt.FOOTER_POST}
                content={content}
                query={query}
                isContentConverted
                clamp={3 - this.state.titleLineCount}
              />
            </div>
          )}
        </div>
        <div>
          <HorizontalSeparator className={styles.horizontalSeparator} />
          <PostSingleRowFooter
            category={category}
            post={post}
            origin="footerPostsItem"
            className={styles.postFooter}
            actionsClassName={styles.postActions}
          />
        </div>
      </div>
    );
  }
}

FooterPostsItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  t: PropTypes.func,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  query: PropTypes.string,
  type: PropTypes.string,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  can: PropTypes.func,
  navigation: PropTypes.object,
  forPublicUser: PropTypes.func.isRequired,
  fetchPostPageData: PropTypes.func.isRequired,
  prefetchPost: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    category: getCategory(state, ownProps.post.categoryId),
    onLikeClick: actions.incrementPostLikeCount,
    fetchPostPageData: actions.fetchPostPageData,
  };
};

export default flowRight(
  withCardBorderWidth,
  withFontClassName,
  withPermissions,
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES]),
)(FooterPostsItem);
