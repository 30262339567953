import React from 'react';
import PropTypes from 'prop-types';
import { DISCUSSION, QUESTION } from '@wix/communities-forum-client-commons';

import CreatePostCta from '../create-post-cta';
import ItemContainer from '../post-list-mobile-common/item-container';
import NoPostsFiltered from '../no-posts-filtered';
import { HorizontalSeparator } from '../separator';
import { CategoryPageFiltersBar } from '../filters-bar-mobile';
import PaginationMobile from '../pagination-mobile';
import FollowCard from '../follow-card';
import PostListCard from '../card/post-list-card';
import MoreButtonMobile, { SIZE_LARGE } from '../more-button-mobile/more-button-mobile';
import { MoreIconCentered } from '../icons/more-icon';
import { MODAL_TYPE_CATEGORY_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import styles from './post-list-mobile-classic.scss';

const PostListMobileClassic = ({
  page,
  category,
  posts,
  postTypes: postTypesProp,
  entityCount,
  query,
  onPageChange,
  buildPageUrl,
  onLikeClick,
  showCreatePostAction,
  showFollowCategoryAction,
  showPinIcon,
  showLockIcon,
  showBestAnswer,
  showMoreButton,
  showViewCount,
  showFilters,
  hasActiveFilter,
  showMemberPosts,
  customCtaLabel,
  postsPerPage,
  categoryActions,
}) => {
  let postTypes;

  if (showCreatePostAction) {
    postTypes = postTypesProp;
  }

  const showCategoryActions = !!category?._id;

  return (
    <div>
      {showCreatePostAction && (
        <React.Fragment>
          <div className={styles.buttonsContainer}>
            {categoryActions.length > 0 && (
              <div className={styles.actionsButtonWrapper}>
                <MoreButtonMobile
                  center
                  showMarkPostsAsRead
                  size={SIZE_LARGE}
                  category={category}
                  Icon={MoreIconCentered}
                  type={MODAL_TYPE_CATEGORY_ACTIONS}
                  showCategoryActions={showCategoryActions}
                  dataHook={showCategoryActions ? 'category-more-button' : 'more-button'}
                />
              </div>
            )}
            <div className={styles.ctaButtonWrapper}>
              <CreatePostCta
                noPadding
                categorySlug={category.slug}
                postTypes={postTypes}
                createPostCtaLabel={customCtaLabel}
              />
            </div>
          </div>
          <HorizontalSeparator />
        </React.Fragment>
      )}
      {showFilters && <CategoryPageFiltersBar category={category} />}
      {!posts.length && hasActiveFilter && <NoPostsFiltered noMemberPosts={showMemberPosts} />}
      {posts.map((post, idx) => (
        <React.Fragment>
          <ItemContainer key={post?._id}>
            <PostListCard
              post={post}
              query={query}
              onLikeClick={onLikeClick}
              showContent={false}
              showPinIcon={showPinIcon}
              showLockIcon={showLockIcon}
              showBestAnswer={showBestAnswer}
              showMoreButton={showMoreButton}
              showViewCount={showViewCount}
              showRecentActivity={true}
            />
          </ItemContainer>
          {idx < posts.length - 1 ? <HorizontalSeparator /> : null}
        </React.Fragment>
      ))}
      <PaginationMobile
        page={page}
        entityCount={entityCount}
        showPerPage={postsPerPage}
        onChange={({ page }) => onPageChange(page)}
        buildPageUrl={buildPageUrl}
      />
      {(showCreatePostAction || showFollowCategoryAction) && <HorizontalSeparator />}
      {showCreatePostAction && (
        <React.Fragment>
          <ItemContainer>
            <CreatePostCta
              categorySlug={category.slug}
              postTypes={postTypes}
              createPostCtaLabel={customCtaLabel}
            />
          </ItemContainer>
        </React.Fragment>
      )}
      {showFollowCategoryAction && (
        <FollowCard withoutPaddingTop={showCreatePostAction} category={category} />
      )}
    </div>
  );
};

PostListMobileClassic.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showFollowCategoryAction: PropTypes.bool,
  showFilters: PropTypes.bool,
  showPinIcon: PropTypes.bool,
  showLockIcon: PropTypes.bool,
  showBestAnswer: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showViewCount: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  onPageChange: PropTypes.func,
  buildPageUrl: PropTypes.func,
  showMemberPosts: PropTypes.bool,
  postTypes: PropTypes.arrayOf(PropTypes.oneOf([QUESTION, DISCUSSION])),
  postsPerPage: PropTypes.number,
};

PostListMobileClassic.defaultProps = {
  showPinIcon: true,
  showLockIcon: true,
  showBestAnswer: true,
  showMoreButton: true,
  showViewCount: true,
};

export default PostListMobileClassic;
